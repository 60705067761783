<script setup lang="ts">
const { openScheduler } = useMeetingScheduler()
</script>

<template>
  <div>
    <BaseIcon name="architectHouse" :size="4" />
    <h2 class="m-0 text-3xl">
      {{ $t('search.scheduleACall.title') }}
    </h2>
    <p class="m-0">
      {{ $t('search.scheduleACall.text') }}
    </p>
    <BaseButton class="w-fit !bg-gray-700" type="button" @click="openScheduler">
      {{ $t('search.scheduleACall.cta') }}
    </BaseButton>
  </div>
</template>
