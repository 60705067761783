<script setup lang="ts">
import { topFacetsAdmin } from '~/helpers/search/topFacets'
import type { Locale } from 'lc-services/types'
import type {
  SearchFacetFilters,
  SearchFacetKeys,
  SearchFacetObject,
  SearchFacetTags,
  SearchFacetValue,
  SearchFacetsGroup,
} from '~/types/search/types'

const props = defineProps<{
  facetFilters: SearchFacetFilters
  facets: Record<SearchFacetTags, Record<string, number>>
  loadingFacets: boolean
  title: string
}>()
const emits = defineEmits<{
  'change-facet': [
    SearchFacetObject<Record<Locale, string>, false>,
    SearchFacetValue,
  ]
  'clear-facets-other': []
}>()

const route = useRoute()
const { locale } = useI18n<unknown, Locale>()

const selectedFacet = ref<string[]>([])

watch(
  () => props.facetFilters,
  (newValue) => {
    if (Object.keys(newValue).length === 0) {
      clearFacetsOther()
    }
  },
)

const searchableFacets = computed(() => {
  if (props.facets[`other_tags.${locale.value}`]) {
    const otherTagsFacets = Object.keys(
      props.facets[`other_tags.${locale.value}`],
    ).sort()

    const excludeFacets = topFacetsAdmin
      .filter((f) => f.key === 'other_tags')
      .map((f) => f.value[locale.value])

    return otherTagsFacets.filter((facet) => {
      return (
        !selectedFacet.value.includes(facet) && !excludeFacets.includes(facet)
      )
    })
  }

  return []
})

const facetsGroupsValues = computed(() => {
  const fgValues: SearchFacetsGroup = []
  Object.entries(props.facets).forEach((x) => {
    const [facetKey, facetValues] = x
    const isOtherTags = facetKey.includes('other_tags')

    if (isOtherTags) {
      const obj = {
        key: 'other_tags' as const,
        value: Object.keys(facetValues),
      }
      fgValues.push(obj)
    }
  })

  return fgValues
})

const isSearchableFacetSelected = computed(() => selectedFacet.value.length > 0)

const clearFacetsOther = () => {
  selectedFacet.value = []
}

const clearFacets = () => {
  clearFacetsOther()
  emits('clear-facets-other')
}

const constructFacet = (facetValue: string, checked: boolean) => {
  const facetGroup = facetsGroupsValues.value.find((g) =>
    g.value.includes(facetValue),
  )?.key

  const facet = {
    key: facetGroup,
    checked,
    value: { [locale.value]: facetValue } as Record<Locale, string>,
  }

  return facet
}

const selectFacet = (facetValue: string) => {
  const facet = constructFacet(facetValue, false)
  changeOtherFacet(facet)
  selectedFacet.value.push(facetValue)
}

const removeFacet = (facetValue: string) => {
  const facet = constructFacet(facetValue, true)
  changeOtherFacet(facet)
  selectedFacet.value = selectedFacet.value.filter((f) => f !== facetValue)
}

const changeOtherFacet = (f: ReturnType<typeof constructFacet>) => {
  const facet = {
    key: f.key as SearchFacetKeys,
    tag: `other_tags.${locale.value as Locale}` as const,
    value: f.value,
  }

  const facetValue = {
    checked: f.checked,
    value: f.value[locale.value],
  }

  if (facet.key) {
    emits('change-facet', facet, facetValue)
  }
}

onMounted(() => {
  const queryFacetFilter = route?.query?.facetFilter
  const searchableFacetsArray = []

  if (Array.isArray(queryFacetFilter)) {
    queryFacetFilter.forEach((queryFacet) => {
      if (queryFacet?.includes('other_tags')) {
        searchableFacetsArray.push(queryFacet)
      }
    })
  } else if (queryFacetFilter) {
    if (queryFacetFilter.includes('other_tags')) {
      searchableFacetsArray.push(queryFacetFilter)
    }
  }
  // Set selected facet
  searchableFacetsArray.forEach((f) => {
    const subtrIndex = f.includes('other_tags') ? 15 : 22
    selectedFacet.value.push(f.substring(subtrIndex))
  })
})

onBeforeUnmount(() => {
  // Set checked to false when no-result appear
  clearFacetsOther()
})
</script>

<template>
  <SearchFilter :title="title">
    <div class="search_facet_admin relative w-full">
      <BaseIcon
        class="absolute left-3 top-1/2 z-dropdown -translate-y-1/2"
        name="search"
      />
      <BaseFormComponentsBaseMultiselect
        :model-value="selectedFacet"
        :can-clear="false"
        :disabled="loadingFacets"
        :options="searchableFacets"
        :placeholder="$t('search.areasEquipmentsSearch')"
        class="mb-4"
        mode="multiple"
        name="search"
        searchable
        wrapper-class="w-full"
        @select="selectFacet"
      >
        <template #multiselect-placeholder>
          <div class="multiselect-placeholder">
            <div class="truncate">
              {{ $t('search.areasEquipmentsSearch') }}
            </div>
          </div>
        </template>

        <template #multiselect-caret>
          <span class="hidden" />
        </template>

        <template #multiselect-multiplelabel>
          <div class="multiselect-multiple-label text-gray-400">
            {{ $t('search.areasEquipmentsSearch') }}
          </div>
        </template>
      </BaseFormComponentsBaseMultiselect>
    </div>

    <ul class="mb-0 mt-2 flex flex-wrap items-center">
      <li
        v-for="facet in selectedFacet"
        :key="facet"
        data-testid="searchable-facet"
        class="mb-2 mr-2 flex flex-none items-center rounded-full bg-gray-700 px-2 py-1 text-xs text-white"
      >
        {{ facet }}
        <BaseIcon
          :size="1"
          class="ml-2 cursor-pointer"
          name="cancel"
          @click="removeFacet(facet)"
        />
      </li>
    </ul>
    <button
      v-if="isSearchableFacetSelected"
      class="text-md font-medium text-gray-700 underline"
      type="button"
      @click="clearFacets"
    >
      {{ $t('search.clearAll') }}
    </button>
  </SearchFilter>
</template>

<style>
.search_facet_admin .multiselect-search,
.search_facet_admin .multiselect-placeholder,
.search_facet_admin .multiselect-multiple-label {
  @apply pl-12;
}
</style>
