<script setup lang="ts">
const localePath = useLocalePath()
const route = useRoute()

const props = defineProps<{
  loading: boolean
  page: number
  showPaginate: boolean
}>()
defineEmits<{
  'next-page': []
}>()

const isServerSide = computed(() => import.meta.server)

const serverUrl = () => {
  if (route?.name?.toString()?.includes('destination')) {
    return localePath({
      name: 'destination-name',
      params: {
        name: route.params.name,
        destination: route.params.destination,
      },
      query: {
        ...route.query,
        page: props.page + 1,
      },
    })
  }

  return localePath({
    name: 'search',
    query: {
      ...route.query,
      page: props.page + 1,
    },
  })
}
</script>

<template>
  <div class="flex flex-col items-center justify-center">
    <BaseButton
      v-if="showPaginate"
      v-show="isServerSide"
      block
      class="absolute left-1/2 -translate-x-1/2"
      color="secondary"
      :to="serverUrl()"
    >
      <span>{{ $t('search.load_more') }}</span>
    </BaseButton>

    <BaseButton
      v-if="showPaginate"
      v-show="!isServerSide"
      :disabled="loading"
      :loader="loading"
      block
      color="secondary"
      @click="$emit('next-page')"
    >
      {{ $t('search.load_more') }}
    </BaseButton>
  </div>
</template>
