<script setup lang="ts">
import type { MultiselectOption } from '~/components/BaseForm/types'
import type { NumericFilter } from '~/types/search/types'

const props = withDefaults(
  defineProps<{
    activeClearButton?: boolean
    attribute: string
    disabled?: boolean
    iconName?: IconPath
    list: MultiselectOption[]
    modelValue?: number
    wrapperClass?: string
  }>(),
  {
    activeClearButton: false,
    disabled: false,
    iconName: '' as IconPath,
    modelValue: 0,
    wrapperClass: 'w-full mb-4',
  },
)
const emits = defineEmits<{
  clear: []
  refine: [string, number] | [NumericFilter, number]
  'update:model-value': [number]
}>()

const { t } = useI18n()
const { isDesktop } = useDevice()

const selection = computed(
  () =>
    `${t(`search.${!isDesktop ? 'min_' : ''}${props.attribute}`, props.modelValue || 0)}`,
)

const showClearButton = computed(
  () => props.activeClearButton && props.modelValue,
)

const refine = (value: number) => {
  emits('refine', props.attribute, value)
  emits('update:model-value', value)
}

const clear = () => {
  emits('clear')
}
</script>

<template>
  <div class="relative">
    <BaseFormComponentsBaseMultiselect
      :can-clear="false"
      :caret="false"
      :data-cy="attribute"
      :model-value="modelValue"
      :name="attribute"
      :disabled="disabled"
      :options="list"
      :wrapper-class="wrapperClass"
      placeholder="e"
      class="search-filter"
      @select="refine"
      @deselect="refine(0)"
    >
      <template v-if="!$device.isMobile" #multiselect-caret>
        <span class="hidden" />
      </template>

      <template #multiselect-singlelabel>
        <div class="multiselect-single-label">
          <BaseIcon
            v-if="!$device.isMobile"
            :color="modelValue ? 'text-gray-700' : 'text-gray-400'"
            :name="iconName"
            class="mr-3"
          />
          <span
            :class="[
              'w-max',
              {
                'text-gray-400': !modelValue,
                'text-gray-700': modelValue,
              },
            ]"
          >
            {{ selection }}
          </span>
        </div>
      </template>
      <template #multiselect-placeholder>
        <div class="multiselect-placeholder">
          <BaseIcon
            v-if="!$device.isMobile"
            :color="modelValue ? 'text-gray-700' : 'text-gray-400'"
            :name="iconName"
            class="mr-2"
          />
          <span
            :class="[
              'w-max',
              {
                'text-gray-400': !modelValue,
                'text-gray-700': modelValue,
              },
            ]"
          >
            {{ selection }}
          </span>
        </div>
      </template>
    </BaseFormComponentsBaseMultiselect>

    <button
      v-show="showClearButton"
      class="absolute right-2.5 top-1/2 flex -translate-y-1/2"
      :aria-label="$t('global.clear')"
      type="button"
      @click="clear"
    >
      <BaseIcon color="text-gray-700" name="close" :size="1" />
    </button>
  </div>
</template>

<style>
@screen md {
  .search-filter .form-select .multiselect__tags {
    padding: 0.875rem 1rem;
  }
  .search-filter .form-select .multiselect__tags,
  .search-filter .form-select .multiselect__single {
    @apply flex flex-nowrap justify-start;
  }
}
</style>
