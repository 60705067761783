<script setup lang="ts">
import type { Locale } from 'lc-services/types'

const props = withDefaults(
  defineProps<{
    linksSeo?: {
      id: string
      url_en: string
      url_fr: string
      title: { en: string; fr: string }
    }[]
    title?: string
  }>(),
  {
    linksSeo: () => [],
    title: '',
  },
)

const { locale } = useI18n<unknown, Locale>()

const links = computed(
  () =>
    props.linksSeo?.map((link) => ({
      id: link.id,
      title: link.title[locale.value],
      url: locale.value === 'fr' ? link.url_fr : link.url_en,
    })) || [],
)
</script>

<template>
  <div class="bg-gray-100 py-10">
    <div class="mx-auto w-full max-w-full px-4 md:px-6 lg:max-w-container-xl">
      <div v-if="title" class="mb-6 text-center text-xl font-bold md:text-left">
        {{ title }}
      </div>
      <div class="columns-1 gap-8 md:columns-3 lg:columns-4">
        <BaseButton
          v-for="link in links"
          :key="link.id"
          :to="link.url"
          class="my-1 w-full text-md leading-5"
          color="black-underline"
          font-weight="font-normal"
          variant="link"
        >
          {{ link.title }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>
