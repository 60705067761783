<script setup lang="ts">
import { topFacetsAdmin, topFacetsClient } from '~/helpers/search/topFacets'
import type { SearchFacetObject, SearchFacetTuple } from '~/types/search/types'

const { locale } = useI18n()

const { userIsAdmin, userIsPartner } = useAuth()

const props = withDefaults(
  defineProps<{
    loadingFacets?: boolean
    facetFilters?: Record<string, string[]>
    facets?: Record<string, Record<string, number>>
  }>(),
  {
    loadingFacets: false,
    facetFilters: () => ({}),
    facets: () => ({}),
  },
)

const emits = defineEmits<{
  'change-exclu': [boolean]
  'change-facet': SearchFacetTuple
  'clear-facets-other': []
}>()

const orderFiltersFacetClient = [
  { key: 5, open: true }, // In the spotlight
  { key: 10, open: true }, // Swimming pool
  { key: 1, open: true }, // Property type
  { key: 2, open: false }, // Environment
  { key: 7, open: false }, // Well-being & Sport
  { key: 4, open: false }, // Amenities
  { key: 3, open: false }, // Property features
]

const orderFiltersFacetAdmin = [
  { key: 1, open: true }, // Property type
  { key: 11, open: true }, // Swimming pool
  { key: 8, open: true }, // Conciergerie
  { key: 9, open: true }, // Licence
  { key: 5, open: true }, // In the spotlight
  { key: 2, open: false }, // Environment
  { key: 7, open: false }, // Well-being & Sport
  { key: 4, open: false }, // Amenities
  { key: 3, open: false }, // Property features
]

const changeFacet = (...args: SearchFacetTuple) => {
  emits('change-facet', ...args)
}
const clearFacetsOther = () => {
  emits('clear-facets-other')
}

const formatFacet = (key: number) => {
  const tag = `searchable_tags.${key}.${locale.value}`

  if (props.facets[tag]) {
    const values = Object.keys(props.facets[tag]).map((value) => {
      const facetQuery = `${tag}: ${value}`

      return {
        value,
        checked: props.facetFilters[key]?.includes(facetQuery) || false,
      }
    })

    return {
      key,
      tag,
      values,
    } as SearchFacetObject
  }
}

const changeExclu = (isExclu: boolean) => {
  emits('change-exclu', isExclu)
}
</script>

<template>
  <div
    :class="[
      'max-h-[calc(100vh-142px-2rem)] overflow-y-auto rounded border border-gray-200 bg-white p-4',
      { 'xl:max-h-[calc(100vh-83px-2rem)': !userIsAdmin && !userIsPartner },
      { 'xl:max-h-[calc(100vh-142px-2rem)': userIsAdmin || userIsPartner },
    ]"
  >
    <template v-if="userIsAdmin || userIsPartner">
      <!-- Top filters -->
      <SearchFilterFeatured
        :facets="facets"
        :facet-filters="facetFilters"
        :loading-facets="loadingFacets"
        :title="$t('search.topFilters')"
        :top-facets="topFacetsAdmin"
        class="mb-4"
        collapse
        collapse-open-default
        display-row
        type="checkboxes"
        @changeFacet="changeFacet"
        @changeExclu="changeExclu"
      />
      <!-- Area and equipments -->
      <SearchFilterAreasAndEquipments
        :title="$t('search.areasEquipments')"
        :loading-facets="loadingFacets"
        :facets="facets"
        :facet-filters="facetFilters"
        class="mb-4"
        @changeFacet="changeFacet"
        @clearFacetsOther="clearFacetsOther"
      />
      <!-- All facets -->
      <SearchFilterFacet
        v-for="filter in orderFiltersFacetAdmin"
        :key="filter.key"
        :collapse-open-default="filter.open"
        :facet="formatFacet(filter.key)"
        :loading-facets="loadingFacets"
        :title="$t(`search.facets.title${filter.key}`)"
        class="mb-4 last:mb-0"
        collapse
        display-row
        type="checkboxes"
        @changeFacet="changeFacet"
      />
    </template>
    <template v-else>
      <SearchFilterFeatured
        :facet-filters="facetFilters"
        :facets="facets"
        :loading-facets="loadingFacets"
        :title="$t('search.topFilters')"
        :top-facets="topFacetsClient"
        class="mb-4 last:mb-0"
        collapse
        collapse-open-default
        display-row
        type="checkboxes"
        @changeFacet="changeFacet"
      />
      <SearchFilterFacet
        v-for="filter in orderFiltersFacetClient"
        :key="filter.key"
        :collapse-open-default="filter.open"
        :facet="formatFacet(filter.key)"
        :loading-facets="loadingFacets"
        :title="$t(`search.facets.title${filter.key}`)"
        class="mb-4 last:mb-0"
        collapse
        display-row
        type="checkboxes"
        @changeFacet="changeFacet"
      />
    </template>
  </div>
</template>
