<script setup lang="ts">
import { getCurrencySymbol, getPriceWithCurrency } from '~/helpers/priceHelper'
import type BaseInput from '../BaseForm/components/BaseInput.vue'

const { locale } = useI18n()
const { currency } = useUser()

const props = withDefaults(
  defineProps<{
    disabled?: boolean
    maxBudget?: string
    minBudget?: string
  }>(),
  {
    disabled: false,
    maxBudget: '1000000',
    minBudget: '0',
  },
)
const emits = defineEmits<{
  'update-budget': [string, string]
}>()

const maxDefaultBudget = '1000000'
const minDefaultBudget = '0'

const budgetMinRef = ref<InstanceType<typeof BaseInput>>()
const budgetMaxRef = ref<InstanceType<typeof BaseInput>>()
const showDropdown = ref(false)

const maxBudgetModel = ref(
  props.maxBudget === maxDefaultBudget ? '' : props.maxBudget,
)
const minBudgetModel = ref(
  props.minBudget === minDefaultBudget ? '' : props.minBudget,
)

const currencyActive = computed(() => getCurrencySymbol(currency.value))
const placeholder = computed(
  () =>
    props.maxBudget === maxDefaultBudget &&
    props.minBudget === minDefaultBudget,
)
const formatedBudget = computed(() => {
  let maxFormatted = getPriceWithCurrency({
    price: Number(props.maxBudget),
    currency: currency.value,
    i18n: { locale },
  })
  const minFormatted = getPriceWithCurrency({
    price: Number(props.minBudget),
    currency: currency.value,
    i18n: { locale },
  })

  let budget = ''

  if (
    props.maxBudget !== maxDefaultBudget &&
    props.minBudget !== minDefaultBudget
  ) {
    if (Number(props.maxBudget) >= 10000) {
      const formatter = Intl.NumberFormat(locale.value, {
        notation: 'compact',
        style: 'currency',
        currency: currency.value,
      })
      maxFormatted = formatter.format(Number(props.maxBudget))
    }

    budget = `${minFormatted} - ${maxFormatted}`
  } else if (
    props.maxBudget !== maxDefaultBudget ||
    props.minBudget !== minDefaultBudget
  ) {
    budget = props.maxBudget !== maxDefaultBudget ? maxFormatted : minFormatted
  }

  return budget
})

const clearBudget = () => {
  maxBudgetModel.value = ''
  minBudgetModel.value = ''
  // clear inputValue because not reactive outside BaseInput
  if (budgetMinRef.value) budgetMinRef.value.inputValue = ''
  if (budgetMaxRef.value) budgetMaxRef.value.inputValue = ''

  emits('update-budget', maxDefaultBudget, minDefaultBudget)
}

const updateBudget = () => {
  const max =
    maxBudgetModel.value === '' ? maxDefaultBudget : maxBudgetModel.value
  const min =
    minBudgetModel.value === '' ? minDefaultBudget : minBudgetModel.value

  emits('update-budget', max, min)

  showDropdown.value = false
}
</script>

<template>
  <BaseDropdown v-model="showDropdown" class="relative" name="searchBudget">
    <template #button>
      <button
        :class="[
          'flex h-[50px] w-full items-center rounded border px-4 font-normal',
          { 'border-gray-200 text-gray-400': !showDropdown && placeholder },
          { 'cursor-not-allowed bg-gray-200': disabled },
        ]"
        :disabled="disabled"
        type="button"
      >
        <BaseIcon name="wallet" class="mr-3" />

        <template v-if="placeholder">
          {{ $t('search.budget') }}
        </template>
        <template v-else>
          {{ formatedBudget }}
        </template>
      </button>
    </template>

    <template #content>
      <div
        class="h-auto w-[calc(100vw-2rem)] rounded-sm border border-gray-200 bg-white px-6 py-4 xl:w-[460px]"
      >
        <div class="mb-4 text-md">
          {{ $t('search.defineTheBudget') }}
        </div>

        <div class="grid grid-cols-2 gap-4">
          <BaseFormComponentsBaseInput
            ref="budgetMinRef"
            v-model="minBudgetModel"
            :label="$t('search.minBudget')"
            :suffix-input="currencyActive"
            id-form="search-budget"
            input-class="w-full"
            inputmode="numeric"
            name="minBudget"
            type="number"
          />
          <BaseFormComponentsBaseInput
            ref="budgetMaxRef"
            v-model="maxBudgetModel"
            :label="$t('search.maxBudget')"
            :suffix-input="currencyActive"
            id-form="search-budget"
            input-class="w-full"
            inputmode="numeric"
            name="maxBudget"
            type="number"
          />
        </div>
        <div class="flex items-center justify-between">
          <BaseButton
            :disabled="disabled"
            class="!text-md"
            color="black"
            variant="link"
            @click="clearBudget"
          >
            {{ $t('search.clean') }}
          </BaseButton>

          <BaseButton
            :disabled="disabled"
            class="!px-4"
            color="secondary"
            @click="updateBudget"
          >
            {{ $t('global.validate') }}
          </BaseButton>
        </div>
      </div>
    </template>
  </BaseDropdown>
</template>
