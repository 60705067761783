<template>
  <div class="border border-gray-200">
    <div class="animate-wave h-[227px] w-full lg:h-[275px]" />
    <div class="p-4">
      <div class="animate-wave mb-1 h-6 w-1/3" />
      <div class="animate-wave mb-3 h-5 w-2/3" />
      <div class="flex items-center">
        <div class="animate-wave h-5 w-2/12 text-gray-600" />
        <div class="px-2 text-gray-400">●</div>
        <div class="animate-wave h-5 w-2/12 text-gray-600" />
        <div class="px-2 text-gray-400">●</div>
        <div class="animate-wave h-5 w-2/12 text-gray-600" />
      </div>
      <div class="mt-3 w-full border-t border-gray-200" />
      <div class="animate-wave mt-3 h-5 w-4/5 border-t border-gray-200" />
    </div>
  </div>
</template>
