<script setup lang="ts">
type SlugName = 'house-slug' | 'house-transaction-slug'
type DetailProp = 'capacity' | 'bedrooms' | 'bathrooms'

const props = withDefaults(
  defineProps<{
    bathrooms: number
    bedrooms: number
    capacity: number
    clickable?: boolean
    destination: string
    id: number
    photos: string[]
    price?: string
    privateToken?: string
    slug: string
    slugName?: SlugName
    title: string
  }>(),
  {
    clickable: true,
    price: '',
    privateToken: '',
    slugName: 'house-slug',
  },
)
const emits = defineEmits<{
  'handle-click': []
}>()
defineSlots<{
  'before-price'(): any
  'card-media'(): any
  'card-action'(): any
  'card-content'(): any
}>()

const { isSmallScreen, isDesktop } = useBreakpoint()
const localePath = useLocalePath()
const nuxtLink = resolveComponent('NuxtLink')

const housePath = computed(() => {
  const path = {
    name: props.slugName,
    params: {
      slug: props.slug,
    },
    query: {},
  }

  if (props.clickable && props.slug && props.privateToken) {
    const queryPrivateToken = {
      ...path,
      query: {
        ...path?.query,
        'private-token': props.privateToken,
      },
    }

    return localePath(queryPrivateToken)
  }

  return props.clickable && props.slug ? localePath(path) : ''
})

const imgSlides = computed(() => {
  return props.photos.map((img, index) => {
    return {
      id: `${index}-${props.id}`,
      src: img,
      position: index + 1,
    }
  })
})

const details: DetailProp[] = ['capacity', 'bedrooms', 'bathrooms']
const getDetail = (detail: DetailProp) => {
  return props[detail]
}
</script>

<template>
  <component
    :is="housePath ? nuxtLink : 'div'"
    :target="$device.isDesktop ? '_blank' : '_self'"
    :to="housePath"
    :class="[
      `
        group -mx-4 block h-full
        overflow-hidden bg-white text-gray-700 hover:no-underline
        hover:shadow-[0_2px_36px_0_rgba(202,202,202,0.5)] md:mx-0 md:rounded md:border
        md:border-gray-200
      `,
      { 'hover:shadow-none': !housePath },
    ]"
    @click="emits('handle-click')"
  >
    <div class="relative h-0 w-full bg-gray-200 p-[66.66%_0_0]">
      <slot name="card-media">
        <VirtualBaseCarousel
          :navigation="isDesktop"
          :pagination="isSmallScreen"
          :slides="imgSlides"
          class-name="house-card__carousel"
          class="!absolute left-0 top-0 size-full"
        >
          <template #slider-content="{ slide, slideIndex }">
            <div class="swiper-slide swiper-slide--overlay">
              <div
                v-if="slideIndex === 5"
                :class="`
                absolute top-0 left-0 w-full h-full z-10
                flex items-center justify-center
                bg-gray-700 bg-opacity-50
                text-white
                md:pt-5
              `"
              >
                <BaseButton
                  :to="housePath"
                  color="white"
                  variant="link"
                  target="_blank"
                >
                  {{ $t('house.seeMore') }}
                </BaseButton>
              </div>
              <BaseNuxtImg
                :alt="title"
                :src="slide.src"
                class="duration-500 ease-in-out group-hover:scale-105"
                sizes="sm:100vw md:50vw lg:50vw xl:25vw xxl:25vw"
              />
            </div>
          </template>
          <template #navigation>
            <div
              class="swiper--prev absolute left-4 top-1/2 z-10 -translate-y-1/2 rounded-full bg-white p-2 opacity-0 duration-500 ease-in-out group-hover:opacity-100"
              @click.stop.prevent
            >
              <BaseIcon name="chevronLeft" />
            </div>
            <div
              class="swiper--next absolute right-4 top-1/2 z-10 -translate-y-1/2 rounded-full bg-white p-2 opacity-0 duration-500 ease-in-out group-hover:opacity-100"
              @click.stop.prevent
            >
              <BaseIcon name="chevronRight" />
            </div>
            <VirtualBaseCarouselPaginationV1 />
          </template>
        </VirtualBaseCarousel>
      </slot>
    </div>
    <div class="relative p-4">
      <div class="absolute right-4 top-4">
        <slot name="card-action" />
      </div>

      <h2 class="mb-0 max-w-[calc(100%-1.5rem)] truncate text-xl">
        {{ title }}
      </h2>
      <div class="mb-1 max-w-full truncate text-md font-medium text-gray-600">
        {{ destination }}
      </div>
      <div class="text-md text-gray-600">
        <span
          v-for="(detail, i) in details"
          :key="i"
          :class="`
            after:content-[''] after:w-0.5 after:h-0.5 after:mx-2
            after:inline-block after:align-middle
            after:bg-gray-600 after:rounded-full
            last:after:hidden
          `"
        >
          {{ getDetail(detail) }}
          {{
            $t(
              `house.${detail}Key${detail === 'bathrooms' ? 'Desktop' : ''}`,
              getDetail(detail),
            )
          }}
        </span>
      </div>

      <slot name="before-price" />

      <div v-if="price" class="mt-4 text-md font-bold">
        {{ price }}
      </div>
    </div>

    <slot name="card-content" />
  </component>
</template>
