import type { Locale } from 'lc-services/types'
import { transformPrice } from '~/helpers/priceHelper'

import type {
  StateSearchResults,
  SearchClientHouse,
} from '~/types/search/types'

export const useSearchClientResults = ({
  hasDates,
}: {
  hasDates: Ref<boolean>
}) => {
  const stateSearchResults = useState<StateSearchResults>(
    'state-search-results',
  )
  const { t, locale } = useI18n<unknown, Locale>({ useScope: 'global' })
  const { currency } = useUser()

  const formattedHits = computed<SearchClientHouse[]>(() => {
    return stateSearchResults.value.hits.map((hit) => {
      const isValidPeriod =
        (hit?.fullyCovered && hit?.allMinimumDurationValid) || false

      const currentPrice = transformPrice({
        currency: currency.value,
        hasDates: hasDates.value,
        hit,
        i18n: {
          locale,
          t,
        },
        isValidPeriod,
        userIsAdmin: false,
        userIsPartner: false,
      })

      return {
        bathrooms: hit.bathrooms,
        bedrooms: hit.bedrooms,
        capacity: hit.capacity,
        currentPrice,
        destination: hit.destination[locale.value],
        displayPrices: hit.display_prices,
        firstPhotoUrl: hit.first_photo_url,
        highlightKeyFeatures:
          hit.highlight_key_features?.map((key) => ({
            identifier: key.identifier,
            name: key.name[locale.value],
          })) || [],
        hotDeal: hit.hot_deal,
        iconicCollection: hit.iconic_collection,
        id: hit.id,
        name: hit.name,
        photos: hit.photos,
        preBooking: hit.pre_booking_available,
        slug: hit.slug[locale.value],
      }
    })
  })

  const hitsHaveTags = (tags: Record<string | number, string>) =>
    Object.entries(tags)
      .map(([index, tag]) => [index, t(tag)] as const)
      .every(
        ([index, tagName]) =>
          stateSearchResults.value.facets[
            `searchable_tags.${index}.${locale.value}`
          ]?.[tagName] > 0,
      )

  return {
    formattedHits,
    hitsHaveTags,
  }
}
